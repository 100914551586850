import React from "react"
import Styled from "styled-components"

const StyledMainSection = Styled.section`
    background-color: ${props => props.backgroundColor};
    padding: 3rem 0;
`

const MainSection = props => {
  return (
    <StyledMainSection backgroundColor={props.backgroundColor}>
      {props.children}
    </StyledMainSection>
  )
}

export default MainSection
