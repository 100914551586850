import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { ThemeProvider } from "styled-components"
import JkfFab from "../jkf-fab"
import { JkfNavbar } from "../jkf-navigation/navbar"
import JkfFooter from "../sections/jkf-footer"

import { theme } from "../theme"

export const MainLayout = ({ children, activeNav }) => {
  const data = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "logos/jkf_logo_hvid.png" }) {
        id
        childImageSharp {
          fixed(height: 40, width: 115) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  return (
    <>
      <ThemeProvider theme={theme}>
        <JkfNavbar
          activeNav={activeNav}
          logo={data.file.childImageSharp.fixed}
        ></JkfNavbar>
        <main>{children}</main>
        <JkfFooter logo={data.file.childImageSharp.fixed} />
        <JkfFab />
      </ThemeProvider>
    </>
  )
}
