import React, { useState } from "react"
import { withTheme } from "styled-components"
import { Fab, Action } from "react-tiny-fab"
import "react-tiny-fab/dist/styles.css"

import { AiOutlineMail, AiOutlinePhone, AiOutlinePlus } from "react-icons/ai"
import { Modal } from "react-bootstrap"
import JkfContactForm from "./jkf-contact-form"

const JkfFab = ({ theme }) => {
  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  return (
    <>
      <Fab
        mainButtonStyles={{ backgroundColor: theme.secondaryColor }}
        icon={
          <AiOutlinePlus size="25" fontWeight="bold" color="rgb(51, 51, 51)" />
        }
        alwaysShowTitle={true}
        event="click"
      >
        <Action
          aria-hidden="false"
          style={{ backgroundColor: theme.mainColor }}
          text="Kontakt"
          onClick={handleShow}
        >
          <AiOutlineMail size="20" />
        </Action>
        <Action
          aria-hidden="false"
          text="Ring"
          style={{ backgroundColor: theme.mainColor }}
        >
          <a aria-label="ring" style={{ color: "white" }} href="tel:75509235">
            <AiOutlinePhone size="20" />
          </a>
        </Action>
      </Fab>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton className="font-weight-bold text-uppercase">
          Kontakt
        </Modal.Header>
        <Modal.Body className="p-4">
          <JkfContactForm />
        </Modal.Body>
      </Modal>
    </>
  )
}

export default withTheme(JkfFab)
