import React from "react"
import Styled from "styled-components"

const Banner = Styled.div`
    background-image: url(${props => props.image || ""});
   
    height: 65vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    align-items: center; 
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align:center;
    p {
        color: ${props => props.theme.secondaryColor};
        margin-bottom: 0;
    }

    @media (min-width: 768px) {
      height: 60vh;
  }
`

export const BannerText = Styled.div`
  color: white;
  
  padding: 2rem 1rem;
  margin: 0 1rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  max-width: 100%;

  text-transform: uppercase;
  background-color: ${props => props.theme.transparent};
  h1 {
      font-size: 1.5rem;
  }

   @media (min-width: 768px) {
       margin: 0;
       padding: 2rem 4rem;
      h1 {
          font-size: 3rem;
      }
  }
  
`

export const MainBanner = ({ image, title, description }) => {
  return (
    <Banner image={image}>
      <BannerText>
        <h1>{title}</h1>
        <p>{description}</p>
      </BannerText>
    </Banner>
  )
}
