import Styled from "styled-components"
import { Link } from "gatsby"

export const JkfSubmitButton = Styled.button`
  border: 1px solid ${props => props.theme.secondaryColor};
       
    text-transform: uppercase;
    padding: 7px 20px;
    text-decoration: none;
    color: ${props =>
      props.filled ? "rgb(51, 51, 51)" : props.theme.secondaryColor};
    position: relative;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
    cursor: pointer;
    background-color: transparent;

    &::after {
        content: '»';
        position: absolute;
        opacity: 0;  
        right: -10px;
        top: 50%;
        transform: translateY(-55%);
        margin: 0 auto;
        transition: 0.5s;
        font-size: 2rem;
        color: black;
    }
    
    &:hover {
        text-decoration: none;
      padding-right: 30px;
      padding-left:10px;
      background-color: ${props => props.theme.secondaryColor}; 
      color: black;

      &::after {
        opacity: 1;
        right: 7px;
      }   
    }    

`

export const JkfButton = Styled(Link)`
    border: 1px solid ${props => props.theme.secondaryColor};
       
    text-transform: uppercase;
    padding: 7px 20px;
    text-decoration: none;
    color: ${props =>
      props.filled ? "rgb(51, 51, 51)" : props.theme.secondaryColor};
    position: relative;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
    cursor: pointer;

    &::after {
        content: '»';
        position: absolute;
        opacity: 0;  
        right: -10px;
        top: 50%;
        transform: translateY(-55%);
        margin: 0 auto;
        transition: 0.5s;
        font-size: 2rem;
        color: black;
    }
    
    &:hover {
        text-decoration: none;
      padding-right: 30px;
      padding-left:10px;
      background-color: ${props => props.theme.secondaryColor}; 
      color: black;

      &::after {
        opacity: 1;
        right: 7px;
      }   
    }    
`
