import { Link } from "gatsby"
import React from "react"
import { Container, Navbar, Nav } from "react-bootstrap"
import Image from "gatsby-image"
import Styled from "styled-components"

const StyledNavbar = Styled(Navbar)`
  background-color: ${props => props.theme.transparent};
`

export const JkfNavbar = ({ activeNav, logo }) => {
  return (
    <StyledNavbar fixed="top" expand="lg" variant="dark" className="appzterBar">
      <Container>
        <Link to="/" className="navbar-brand pb-0">
          <Image loading="eager" fixed={logo} alt="Appzter logo" />
        </Link>

        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav
            activeKey={activeNav}
            className="ml-auto text-uppercase d-flex flex-column align-items-center flex-lg-row align-items-lg-start"
          >
            <Nav.Link as={Link} eventKey="/" to="/">
              Forside
            </Nav.Link>
            <Nav.Link as={Link} eventKey="/products" to="/products/">
              Fornyelse
            </Nav.Link>
            <Nav.Link as={Link} eventKey="/koncept" to="/koncept/">
              Koncept
            </Nav.Link>
            <Nav.Link as={Link} eventKey="/kontakt-os" to="/kontakt-os/">
              Kontakt
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </StyledNavbar>
  )
}
