import React from "react"
import { Col, Row, Container } from "react-bootstrap"
import {
  AiFillFacebook,
  AiFillLinkedin,
  AiFillTwitterSquare,
} from "react-icons/ai"
import { JkfButton } from "../buttons/jkf-button-link"
import MainSection from "./main-section"
import Image from "gatsby-image"

const JkfFooter = ({ logo }) => {
  return (
    <footer>
      <MainSection backgroundColor="rgb(51, 51, 51)">
        <Container>
          <Row>
            <Col xs={12} md={4}>
              <div className="d-flex flex-column align-items-center align-items-md-start mb-5 mt-4 mb-md-0 mt-md-0">
                <h2
                  className="text-center text-md-left"
                  style={{ color: "white", marginBottom: 20 }}
                >
                  Er du interesseret i at samarbejde?
                </h2>
                <div>
                  <JkfButton to="/kontakt-os/">Kontakt os</JkfButton>
                </div>
                <div className="mt-3">
                  <AiFillFacebook
                    style={{ color: "white", height: 40, width: 40 }}
                  />
                  <AiFillLinkedin
                    style={{ color: "white", height: 40, width: 40 }}
                  />
                  <AiFillTwitterSquare
                    style={{ color: "white", height: 40, width: 40 }}
                  />
                </div>
              </div>
            </Col>
            <Col xs={12} md={8}>
              <div
                style={{ color: "white" }}
                className="d-flex flex-column align-items-center text-left align-items-md-end"
              >
                <div className="mb-3">
                  <Image fixed={logo} />
                </div>
                <div className="d-flex flex-column">
                  <span>CARL PLOUGS VEJ 67</span>
                  <span>6000 KOLDING</span>

                  <span>info@jysk-kf.dk</span>
                  <span>TLF. 75 50 92 35</span>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </MainSection>
    </footer>
  )
}

export default JkfFooter
