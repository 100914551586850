import React from "react"
import { Form, Col } from "react-bootstrap"
import { JkfSubmitButton } from "./buttons/jkf-button-link"

const JkfContactForm = () => {
  return (
    <Form action="https://formspree.io/f/xgeplzko" method="POST">
      <Form.Row className="mb-2">
        <Col xs={12} md={6} className="mb-3 md-md-0">
          <Form.Label>Navn</Form.Label>
          <Form.Control
            required={true}
            style={{ backgroundColor: "#f4f4f4", borderRadius: 0 }}
            name="name"
            type="text"
            placeholder="Indtast navn"
          />
        </Col>
        <Col xs={12} md={6}>
          <Form.Label>Email</Form.Label>
          <Form.Control
            required={true}
            name="email"
            type="email"
            placeholder="Indtast email"
            style={{ backgroundColor: "#f4f4f4", borderRadius: 0 }}
          />
        </Col>
      </Form.Row>
      <Form.Row>
        <Col xs={12}>
          <Form.Label>Besked</Form.Label>
          <Form.Control
            required={true}
            name="message"
            as="textarea"
            rows="5"
            style={{ backgroundColor: "#f4f4f4", borderRadius: 0 }}
          />
        </Col>
      </Form.Row>
      <div className="d-flex justify-content-center mt-3">
        <JkfSubmitButton type="submit">Send besked</JkfSubmitButton>
      </div>
    </Form>
  )
}

export default JkfContactForm
